import React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Know = () => (
  <Layout>
    <Seo title="About" />
    {/* <!-- This example requires Tailwind CSS v2.0+ --> */}

    <section className="bg-coolGray-100 text-coolGray-800">
      <div className="container  lg:h-screen mx-auto flex flex-col items-center px-4 py-16 text-center md:py-32 md:px-10 lg:px-32 xl:max-w-3xl items-center justify-center ">
        <h1 className="text-4xl font-bold leading-none sm:text-5xl animate-pulse text-emerald-500">
          HearMe
        </h1>
        <p className="px-8 mt-8 mb-12 text-lg">
          Hear me is a project built on the foundation to help assist the
          disabled. The goal for this project was to help deliver a product that
          is adopted easily by the community.
        </p>
      </div>
    </section>

    <div className="bg-coolGray-100 text-coolGray-900">
      <div className="container grid grid-cols-12 mx-auto">
        <div
          className="flex flex-col justify-center col-span-12 align- rounded-lg bg-no-repeat bg-cover bg-coolGray-700 lg:col-span-6 lg:h-auto shadow-md"
          style={{
            backgroundImage: `url('https://source.unsplash.com/random/640x480')`,
            backgroundPosition: `center center`,
            backgroundBlendMode: ` multiply`,
            backgroundSize: `cover`,
          }}
        >
          <div className="flex flex-col items-center p-8 py-12 text-center">
            <img
              src="https://i.ibb.co/FnNhFKX/image-2022-03-21-164426.png "
              alt=""
              className="object-contain lg:h-96 xl:h-112 2xl:h-128  flex justify-center  content-center"
            />
          </div>
        </div>
        <div className="flex flex-col col-span-12 p-6 divide-y lg:col-span-6 lg:p-10 divide-coolGray-300">
          <div className="pt-6 pb-4 space-y-2">
            <span>05 November 2021</span>
            <h1 className="text-3xl font-bold text-emerald-500">
              Why I Developed HearMe for $0.
            </h1>
            <p>I felt disappointed in not being able to understa...</p>
            <a
              rel="noopener noreferrer"
              href="/why-develop-hearme"
              className="inline-flex items-center py-2 space-x-2 text-sm text-violet-600"
            >
              <span>Read more</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-4 h-4"
              >
                <path
                  fillRule="evenodd"
                  d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
          <div className="pt-6 pb-4 space-y-2">
            <span>12 December 2021</span>
            <h1 className="text-3xl font-bold text-emerald-500">
              TV interviews and Awards
            </h1>
            <p>HearMe really grabbed the attention of a lot of ......</p>
            <a
              rel="noopener noreferrer"
              href="/interviewsawards"
              className="inline-flex items-center py-2 space-x-2 text-sm text-violet-600"
            >
              <span>Read more</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-4 h-4"
              >
                <path
                  fillRule="evenodd"
                  d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
          <div className="pt-6 pb-4 space-y-2">
            <span>17 March 2022</span>
            <h1 className="text-3xl font-bold text-emerald-500">
              Project Contributors
            </h1>
            <p>After suffering from burnout I had to be helped by.....</p>
            <a
              rel="noopener noreferrer"
              href="/projectcontributors"
              className="inline-flex items-center py-2 space-x-2 text-sm text-violet-600"
            >
              <span>Read more</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-4 h-4"
              >
                <path
                  fillRule="evenodd"
                  d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>

    <section class="text-center">
      <div class="max-w-screen-xl px-4 py-8 mx-auto sm:px-6 lg:px-8">
        <ul class="grid grid-cols-2 gap-4 border-2 border-teal-600 rounded-xl lg:grid-cols-4">
          <li class="p-8">
            <p class="text-2xl font-extrabold text-teal-500">500+</p>
            <p class="mt-1 text-lg font-medium">users</p>
          </li>

          <li class="p-8">
            <p class="text-2xl font-extrabold text-teal-500">4k+</p>
            <p class="mt-1 text-lg font-medium">Impressions</p>
          </li>

          <li class="p-8">
            <p class="text-2xl font-extrabold text-teal-500">200+</p>
            <p class="mt-1 text-lg font-medium">Reviews</p>
          </li>

          <li class="p-8">
            <p class="text-2xl font-extrabold text-teal-500">7</p>
            <p class="mt-1 text-lg font-medium">Staff</p>
          </li>
        </ul>
      </div>
    </section>

    <section className="my-8">
      <div className="container mx-auto flex flex-col items-center pb-6 mb-4 md:p-10 md:px-12">
        <h1 className="text-4xl font-semibold leading-none text-center text-emerald-500">
          What our users are saying about us
        </h1>
      </div>
      <div className="container mx-auto grid grid-cols-1 gap-8 lg:gap-20 md:px-10 md:pb-10 lg:grid-cols-2">
        <div className="flex flex-col items-center mx-2 lg:mx-0">
          <div className="relative text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              fill="currentColor"
              className="absolute top-0 left-0 w-8 h-8 text-emerald-300"
            >
              <path d="M232,246.857V16H16V416H54.4ZM48,48H200V233.143L48,377.905Z"></path>
              <path d="M280,416h38.4L496,246.857V16H280ZM312,48H464V233.143L312,377.905Z"></path>
            </svg>
            <p className="px-6 py-1 text-lg italic">
              Using HearMe really changed my life. I can freely communicate with
              anyone. This gives me confidence as someone who is shy. HearMe has
              bossted myself esteem.
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              fill="currentColor"
              className="absolute bottom-0 right-0 w-8 h-8 text-emerald-300"
            >
              <path d="M280,185.143V416H496V16H457.6ZM464,384H312V198.857L464,54.1Z"></path>
              <path d="M232,16H193.6L16,185.143V416H232ZM200,384H48V198.857L200,54.1Z"></path>
            </svg>
          </div>
          <span className="w-12 h-1 my-2 rounded-lg bg-emerald-600"></span>
          <p>Tinashe</p>
        </div>
        <div className="flex flex-col items-center max-w-lg mx-2 lg:mx-0">
          <div className="relative text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className="absolute top-0 left-0 w-8 h-8 text-emerald-300"
            >
              <path
                fill="currentColor"
                d="M232,246.857V16H16V416H54.4ZM48,48H200V233.143L48,377.905Z"
              ></path>
              <path
                fill="currentColor"
                d="M280,416h38.4L496,246.857V16H280ZM312,48H464V233.143L312,377.905Z"
              ></path>
            </svg>
            <p className="px-6 py-1 text-lg italic">
              I was really out of expressions when I got introduced to HearMe at
              my workplace. It was a challenge for me to present at work without
              a translator. Now I just use HearMe for all my presentations.
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className="absolute bottom-0 right-0 w-8 h-8 text-emerald-300"
            >
              <path
                fill="currentColor"
                d="M280,185.143V416H496V16H457.6ZM464,384H312V198.857L464,54.1Z"
              ></path>
              <path
                fill="currentColor"
                d="M232,16H193.6L16,185.143V416H232ZM200,384H48V198.857L200,54.1Z"
              ></path>
            </svg>
          </div>
          <span className="w-12 h-1 my-2 rounded-lg bg-emerald-600"></span>
          <p>Ruth</p>
        </div>
      </div>
    </section>
    <nav
      aria-label="breadcrumb"
      className="w-full p-4 dark:bg-coolGray-800 dark:text-coolGray-100 flex flex-col items-center justify-center"
    >
      <ol className="flex h-8 space-x-2 dark:text-coolGray-100">
        <li className="flex items-center">
          <a
            rel="noopener noreferrer"
            href="/"
            title="Back to homepage"
            className="flex items-center hover:underline"
          >
            Home
          </a>
        </li>
        <li className="flex items-center space-x-1">
          <span className="dark:text-coolGray-400">/</span>
          <a
            rel="noopener noreferrer"
            href="/know"
            className="flex items-center px-1 capitalize hover:underline font-bold "
          >
            About
          </a>
        </li>
        <li className="flex items-center space-x-1">
          <span className="dark:text-coolGray-400">/</span>
          <a
            rel="noopener noreferrer"
            href="/started"
            className="flex items-center px-1 capitalize hover:underline"
          >
            Features
          </a>
        </li>
        <li className="flex items-center space-x-1">
          <span className="dark:text-coolGray-400">/</span>
          <a
            rel="noopener noreferrer"
            href="/contact"
            className="flex items-center px-1 capitalize hover:underline"
          >
            Contact
          </a>
        </li>
      </ol>
      <footer className="animate-pulse">
        © {new Date().getFullYear()}, An Idea by
        {` `}
        <a className="text-center text-sm" href="https://www.chakama.co.zw">
          George Chakama
        </a>
      </footer>
    </nav>
    {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <StaticImage
        src="../images/gatsby-astronaut.png"
        alt="gatsby astronaut"
      />
    </div>
    <p>
      <Link to="/page-2/">Go to page 2</Link>
    </p>
    <p>
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link>
    </p> */}
  </Layout>
)

export default Know
